<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="background: #fafafa">
        <div class="text-center">
            <v-dialog
                    v-model="dialog"
                    persistent
                    width="50"
            >
                <img src='../../assets/loader_spinner.gif' style="width: 50px; ">

            </v-dialog>
        </div>
        <v-row>
            <v-col>
                <v-container class="my-8 px-3">
                    <v-row class="px-3">
                        <v-col>
                            <v-row>
                                <v-col cols="0" md="2"></v-col>
                                <v-col cols="12" md="8" class="day-site my-8" style="color:#1976d2 !important">
                                    {{resultMessage}}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="hidden-sm-and-down">
                                    <ul style="" class="roundBtn-blue calc-4">
                                        <li></li>
                                        <li>
                                            <a @click="btnClick(false)">메인</a>
                                        </li>
                                        <li>
                                            <a @click="btnClick(true)">추가예약</a>
                                        </li>
                                        <li></li>
                                    </ul>
                                </v-col>
                                <v-col cols="12" class="hidden-md-and-up">
                                    <ul style="" class="roundBtn-blue calc-2">
                                        <li>
                                            <a @click="btnClick(false)">메인</a>
                                        </li>
                                        <li>
                                            <a @click="btnClick(true)">추가예약</a>
                                        </li>
                                    </ul>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="0" md="2"></v-col>
                                <v-col cols="12" md="8">
                                    <v-row>
                                        <v-col cols="12" class="hidden-sm-and-down">
                                            <ul class="resvDateRangeBox">
                                                <li class="">
                                                    <div>
                                                        <span class="resvDateRangeSub">체크인</span>
                                                        {{this.resvSelectData.resvStartDate}}({{this.resvSelectData.resvStartDayOfWeek}})
                                                    </div>
                                                    <!--<div class="day-range-display1">오후 2시 이후</div>-->
                                                </li>
                                                <li class="resvDateRangeBox_dateRage">
                                                    {{resvSelectData.resvDateRange}}
                                                </li>
                                                <li class="">
                                                    <div>
                                                        <span class="resvDateRangeSub">체크아웃</span>
                                                        {{this.resvSelectData.resvEndDate}}({{this.resvSelectData.resvEndDayOfWeek}})
                                                    </div>
                                                </li>
                                            </ul>
                                        </v-col>
                                        <v-col cols="12" class="hidden-md-and-up">
                                            <table class="resvDateRangeTable">
                                                <tr>
                                                    <td>
                                                        <div class="resvDateRangeSub">체크인</div>
                                                        <div>
                                                            {{this.resvSelectData.resvStartDate}}({{this.resvSelectData.resvStartDayOfWeek}})
                                                        </div>
                                                    </td>
                                                    <td class="resvDateRangeTable_dateRage">
                                                        {{resvSelectData.resvDateRange}}
                                                    </td>
                                                    <td>
                                                        <div class="resvDateRangeSub">체크아웃</div>
                                                        <div>
                                                            {{this.resvSelectData.resvEndDate}}({{this.resvSelectData.resvEndDayOfWeek}})
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12" class="sub-title-3 mt-6">
                                            예약내용
                                        </v-col>
                                        <template v-if="!isMobile">
                                            <table class="table-top-s table-top-s-ts">
                                                <colgroup>
                                                    <col style="width:30%"/>
                                                    <col style="width:25%"/>
                                                    <col style="width:20%"/>
                                                    <col style="width:25%"/>
                                                </colgroup>
                                                <tr>
                                                    <th>객실명</th>
                                                    <th>기준/최대</th>
                                                    <th>예약인원</th>
                                                    <th class="num-right">이용요금</th>
                                                </tr>
                                                <tr
                                                        v-for="(item,i) in resvConfirmRoomList"
                                                        :key="i">
                                                    <td>{{item.roomNm}}</td>
                                                    <td>{{item.stdNop}}명/{{item.maxNop}}명</td>
                                                    <td>{{item.resvNop}}명</td>
                                                    <td class="num-right">{{item.resvPrice | comma}}원</td>
                                                </tr>
                                                <tr>
                                                    <td>전체</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="num-right">{{resvConfirmInfo.roomPrice | comma}}원</td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                        </template>
                                        <template v-else >
                                            <table class="table-top-s" style="border-top:2px solid gray;">
                                                <colgroup>

                                                    <col style="width:40%"/>
                                                    <col style="width:20%"/>
                                                    <col style="width:33%"/>
                                                    <col style="width:7%"/>
                                                </colgroup>
                                                <tr>
                                                    <th class="pl-4">객실</th>
                                                    <th>인원</th>
                                                    <th class="num-right">요금</th>
                                                    <th></th>
                                                </tr>
                                                <tr
                                                        v-for="(item,i) in this.resvConfirmRoomList"
                                                        :key="i">
                                                    <td>{{item.roomNm}} <br> <span style="font-size: 0.7rem; color: gray;">{{item.roomForm}}</span>
                                                    <td>{{item.resvNop}}명</td>
                                                    <td class="num-right">{{item.resvPrice | comma}}원</td>
                                                    <td>
                                                        <v-btn
                                                                icon
                                                                x-small
                                                                color="pink"
                                                                @click="delRoom(item)"
                                                        >
                                                            <v-icon>mdi-close</v-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                                <tr style="height: 50px;">
                                                    <td>전체금액</td>
                                                    <td></td>
                                                    <td class="num-right">{{resvConfirmInfo.roomPrice | comma}}원</td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                        </template>
                                    </v-row>
                                    <v-row v-if="isCampItem">
                                        <v-col cols="12" class="sub-title-3 mt-6">
                                            추가요금
                                        </v-col>
                                        <template v-if="!isMobile">
                                            <table class="table-top-s">
                                                <colgroup>
                                                    <col style="width:10%"/>
                                                    <col style="width:40%"/>
                                                    <col style="width:20%"/>
                                                    <col style="width:10%"/>
                                                    <col style="width:20%"/>
                                                </colgroup>
                                                <tr>
                                                    <th></th>
                                                    <th>해당내역</th>
                                                    <th>단가</th>
                                                    <th>수량</th>
                                                    <th class="num-right">이용요금</th>
                                                </tr>
                                                <tr
                                                        v-for="(item,i) in this.campItem"
                                                        :key="i">
                                                    <td class="mx-auto">
                                                    </td>
                                                    <td>{{item.campName}}</td>
                                                    <td>{{item.campPrice | comma}}원</td>
                                                    <td>{{item.campQty}}</td>
                                                    <td class="num-right">{{item.campPrices | comma}}원</td>
                                                </tr>
                                            </table>
                                        </template>
                                        <template v-else>
                                            <table class="table-top-s">
                                                <colgroup>
                                                    <col style="width:40%"/>
                                                    <col style="width:20%"/>
                                                    <col style="width:20%"/>
                                                </colgroup>
                                                <tr>
                                                    <th class="pl-4">부대시설</th>
                                                    <th>수량</th>
                                                    <th class="num-right">요금</th>
                                                </tr>
                                                <tr
                                                        v-for="(item,i) in this.campItem"
                                                        :key="i">
                                                    <td class="pl-4">{{item.campName}} <br> <span style="color: #1976d2 ;">({{item.campPrice | comma}}원) </span></td>
                                                    <td>{{item.campQty}}</td>
                                                    <td class="num-right">{{item.campPrices | comma}}원</td>
                                                </tr>
                                            </table>
                                        </template>

                                    </v-row>

                                    <v-row>
                                        <v-col cols="12" class="sub-title-3 mt-6">
                                            예약자 정보
                                        </v-col>
                                        <template v-if="!isMobile">
                                            <table class="table-left-s">
                                                <colgroup>
                                                    <col style="width:30%"/>
                                                    <col style="width:70%"/>
                                                </colgroup>
                                                <tr>
                                                    <th class="th">예약자명</th>
                                                    <td class="td">
                                                        {{resvConfirmInfo.resvName}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th class="th">휴대전화</th>
                                                    <td class="td-phone">
                                                        {{resvConfirmInfo.resvPhone}}

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th class="th">문의사항</th>
                                                    <td class="td py-4" colspan="3" style=" white-space: pre;">
                                                        {{resvConfirmInfo.resvMemo}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </template>
                                        <template v-else>
                                            <table class="table-left-s mobile">
                                                <colgroup>
                                                    <col style="width:30%"/>
                                                    <col style="width:70%"/>
                                                </colgroup>
                                                <tr>
                                                    <th class="th" style="font-size:0.9rem;">예약자명</th>
                                                    <td class="td">
                                                        {{resvConfirmInfo.resvName}}

                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th class="th" style="font-size:0.9rem;">연락번호</th>
                                                    <td class="td">
                                                        {{resvConfirmInfo.resvPhone}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th class="th" style="font-size:0.9rem;">남기실말씀</th>
                                                    <td class="td">
                                                        {{resvConfirmInfo.resvMemo}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </template>

                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="sub-title-3 mt-6">
                                            결제정보
                                        </v-col>
                                        <template v-if="!isMobile">
                                            <table class="table-left-s">
                                                <colgroup>
                                                    <col style="width:25%"/>
                                                    <col style="width:75%"/>
                                                </colgroup>
                                                <tr>
                                                    <th class="th">결제금액</th>
                                                    <td class="td red--text" style="font-size: 16px; padding:5px;">
                                                        {{resvConfirmInfo.resvPrice | comma}}원
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th class="th">결제수단</th>
                                                    <td class="td">
                                                        {{payInfo.payTypeDesc}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th class="th">입금계좌</th>
                                                    <td class="td" style="font-size: 1rem; font-weight: bold;padding-top: 5px;padding-bottom: 5px;">
                                                        {{payInfo.bank}} <span style="color: red;">{{payInfo.accountNumber}}</span> {{payInfo.accName}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th class="th">입 금 자</th>
                                                    <td class="td" style="color:darkblue; font-weight: bold;">
                                                        {{resvConfirmInfo.payName}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </template>
                                        <template v-else>
                                            <table class="table-left-s mobile">
                                                <colgroup>
                                                    <col style="width:30%"/>
                                                    <col style="width:70%"/>
                                                </colgroup>
                                                <tr>
                                                    <th class="th">결제금액</th>
                                                    <td class="td red--text" style="font-size: 16px; padding:5px;">
                                                        {{resvConfirmInfo.resvPrice | comma}}원
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th class="th">결제수단</th>
                                                    <td class="td">
                                                        {{payInfo.payTypeDesc}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th class="th">입금계좌</th>
                                                    <td class="td" style="font-size: 1rem; font-weight: bold;padding-top: 5px;padding-bottom: 5px;">
                                                        {{payInfo.bank}} <span class="text--blue">{{payInfo.accountNumber}}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th class="th">입 금 자</th>
                                                    <td class="td" style="color:darkblue; font-weight: bold;">
                                                        {{resvConfirmInfo.payName}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </template>

                                        <v-col cols="12"
                                               style="font-size: 1.2rem; padding-top:3px;color:darkblue;font-weight: bold;">
                                            ※유의사항 및 환불규정 등은 이용안내를 확인해주세요!
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    export default {
        name: 'ReservationSelect',
        components: {},
        data: () => ({
            resvId: "",
            resvConfirmInfo: {
                roomData: [],
                resvPrice: 0,
                payType: "0",
            },
            resvConfirmRoomList: [],
            resvSelectData: {},
            payGubun: "One",
            payInfo: {
                accountNumber: "352-0170-6948-03 예금주 최정자",
                bank: "농협",
                payTypeDesc: "무통장"

            },
            campItem: [],
            isCampItem: true,
            isSuccess: true,
            resultMessage : "",
            dialog: true,
            isMobile: false,


        }),
        created: function () {

            // 모바일 체크
            this.isMobile = this.$isMobile();

            window.scrollTo(0, 0);
            // 예약 리스트 삭제
            this.$store.commit("reservation/CLEAR_RESV_CONFIRM", this.resvConfirmList);
            let _this = this;
            this.dialog = true;

            const sch = window.location.search;
            //const urlParams = url.searchParams;
            var urlParams = new URLSearchParams(sch);

            const resvId = urlParams.get('resvId');
            this.resvId = resvId;

            //this.resvId = resvId = urlParams.get('resvId');

            const confirmGubun = urlParams.get('confirmGubun');

            const orderId = urlParams.get('orderId');
            const paymentKey = urlParams.get('paymentKey');
            const amount = urlParams.get('amount');
            const sk1 = 'test_sk_aBX7zk2yd8yxAvwWwwBrx9POLqKQ';
            const sk = '{"test_sk_aBX7zk2yd8yxAvwWwwBrx9POLqKQ":}';


            if (resvId === null) {
                alert("잘못된 요청입니다.");
                window.history.back();
            }

            if (confirmGubun === "1") {
                this.isSuccess = false;
            }


            if (orderId !== null && paymentKey !== null && amount !== null) {
                let formdata = {};
                this.isSuccess = true;

                const http = require("https");

                const base_sk = btoa(sk);

                const options = {
                    "method": "POST",
                    "hostname": "api.tosspayments.com",
                    "port": null,
                    "path": "/v1/payments/" + paymentKey,
                    "headers": {
                        "Authorization": "Basic dGVzdF9za19hQlg3emsyeWQ4eXhBdndXd3dCcng5UE9McUtROg==",
                        "Content-Type": "application/json"
                    }


                };

                const req = http.request(options, function (res) {
                    const chunks = [];

                    res.on("data", function (chunk) {
                        chunks.push(chunk);
                    });

                    res.on("end", function () {
                        const body = Buffer.concat(chunks);

                        const result = JSON.parse(body.toString());

                        setTimeout(() => {

                            if (result.orderId !== undefined) {
                                //if(true){
                                const payType = "1";
                                const accountNumber = result.virtualAccount.accountNumber;
                                const accountType = result.virtualAccount.accountType;
                                const bank = result.virtualAccount.bank;
                                const customerName = result.virtualAccount.customerName;

                                const secret = result.secret;

                                //const paymentKey
                                //const orderId
                                //const amount =

                                formdata = {
                                    payType: payType,
                                    accountNumber: accountNumber,
                                    accountType: accountType,
                                    bank: bank,
                                    customerName: customerName,
                                    secret: secret,
                                    paymentKey: paymentKey,
                                    orderId: orderId,
                                    amount: amount,
                                    resvId: resvId,

                                };

                                _this.$store.dispatch("reservation/orderUpdate", formdata)
                                    .then((resp) => {
                                        _this.getResvData()


                                    })
                                    .catch((err) => {
                                    })

                            }else if(result.code !== null && result.code !== undefined){

                                _this.resultMessage = "예약이 실패 했습니다.";
                                alert(result.code + " / " +result.message);

                                _this.resvDelete(_this.resvId);

                            } else {

                                _this.getResvData()

                            }

                        }, 300)


                    });
                });

                req.write(JSON.stringify({amount: amount, orderId: orderId}));
                req.end();

            } else {

                _this.getResvData()

            }

        },
        beforeMount() {
            this.$store.commit("reservation/CLEAR");
            this.$store.commit("reservation/CLEAR_RESV_USR_INFO");
        },
        beforeDestroy() {
            //this.$store.commit("reservation/CLEAR_RESV_CONFIRM_INFO", this.$route.params);
        },
        methods: {
            getResvData() {
                return this.$store.dispatch("reservation/getResvConfirm", {resvId: this.resvId})
                    .then((resp) => {
                        setTimeout(() => {

                            this.resvConfirmInfo = resp.message[0];
                            this.resvConfirmRoomList = this.resvConfirmInfo.roomData;

                            this.resvSelectData = this.resvConfirmRoomList[0];

                            let week = ['일', '월', '화', '수', '목', '금', '토'];

                            //this.dates.startDate = start.format('YYYY-MM-DD');
                            //this.dates.endDate = end.format('YYYY-MM-DD');
                            this.resvSelectData.resvStartDayOfWeek = week[new Date(this.resvSelectData.resvStartDate).getDay()];
                            this.resvSelectData.resvEndDayOfWeek = week[new Date(this.resvSelectData.resvEndDate).getDay()];

                            //객실 금액 계산
                            this.resvConfirmInfo.roomPrice = 0;
                            for(let i = 0; this.resvConfirmRoomList.length > i; i++){

                                this.resvConfirmInfo.roomPrice += Number(this.resvConfirmRoomList[i].resvPrice);

                            }

                            if(this.resvConfirmInfo.campItem != undefined && this.resvConfirmInfo.campItem.length > 0){
                                this.isCampItem = true;

                                if (this.resvConfirmInfo.campItem.length > 0) {
                                    this.campItem = this.resvConfirmInfo.campItem;

                                    for (let i = 0; this.campItem.length > i; i++) {
                                        this.campItem[i].campPrices = (this.campItem[i].campQty * this.campItem[i].campPrice);

                                    }

                                }
                            }else {
                                this.isCampItem = false;
                            }

                            // 핸드폰 번호 변경
                            this.resvConfirmInfo.resvPhone = this.$setPhoneNum(this.resvConfirmInfo.resvPhone);

                            this.resultMessage = "예약이 성공적으로 되었습니다.";

                            // 계좌이체 무통장 구분
                            if (this.resvConfirmInfo.payType === "1") { // 계좌이체

                                this.payInfo.accountNumber = this.resvConfirmInfo.accountNumber;
                                this.payInfo.bank = this.resvConfirmInfo.bank;
                                this.payInfo.payTypeDesc = "계좌이체";


                            } else { // 무통장

                                //this.payInfo.accountNumber = "352-0170-6948-03 예금주 최정자";
                                //this.payInfo.bank = "농협";
                                //this.payInfo.payTypeDesc = "무통장";

                                return this.$store.dispatch("admin/getComn", {comnGubun: 'accList'})
                                    .then((resp) => {
                                        setTimeout(() => {

                                            if (resp.message !== undefined && resp.message.comnCont !== undefined) {
                                                let payInfoTmp = JSON.parse(resp.message.comnCont)[0];

                                                if(payInfoTmp.accBnkNm != undefined && payInfoTmp.accBnkNm.length > 0){
                                                    this.payInfo.bank = payInfoTmp.accBnkNm;
                                                    this.payInfo.accountNumber = payInfoTmp.accNum;
                                                    this.payInfo.accName = payInfoTmp.accResvNm;

                                                }else {
                                                    this.payInfo.accountNumber = "352-0170-6948-03";
                                                    this.payInfo.accName = "최정자";
                                                    this.payInfo.bank = "농협";

                                                }
                                                this.payInfo.payTypeDesc = "무통장";
                                                this.dialog = false;
                                            }


                                        }, 300)
                                    })
                                    .catch((err) => {
                                    })


                            }

                            this.dialog = false;


                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            btnClick(val) {

                if (val) {    // 추가
                    this.$router.push({path: "/reservation/search"});
                } else {  // 메인
                    this.$router.push({path: "/"});

                }
            },
            resvDelete(val){    // 실패 할경우 넘어옴

                if(val !== undefined){
                    return this.$store.dispatch("reservation/resvDelete", {resvId: val})
                        .then((resp) => {

                            setTimeout(() => {
                                this.$router.push({path: "/reservation/search"});
                            }, 1000);

                        })
                        .catch((err) => {
                        })
                }

            },

        },
        mounted() {
            this.$nextTick(() => {

            })
        },
        computed: {},
    }
</script>

<style>
    .disable-events {
        pointer-events: none
    }

    .selectItem {
        border: 1px solid #039BE5;
    }
</style>
